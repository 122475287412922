import React  from 'react';

import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const GlobeSVG = () => <svg className="property-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><line x1="2" y1="12" x2="22" y2="12"/><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"/></svg>;
const LocationSVG = () => <svg className="property-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" /><circle cx="12" cy="10" r="3" /></svg>;
const FlagSVG = () => <svg className="property-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z" /><line x1="4" y1="22" x2="4" y2="15" /></svg>;
const HashSVG = () => <svg className="property-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="4" y1="9" x2="20" y2="9" /><line x1="4" y1="15" x2="20" y2="15" /><line x1="10" y1="3" x2="8" y2="21" /><line x1="16" y1="3" x2="14" y2="21" /></svg>;
const MessageSVG = () => <svg className="property-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" /></svg>;

const LocationCard = ({ title, location }) => {
  return <Box className="card">
    <Typography className="cardHeader">{title}</Typography>
    
    <div className="properties">
      <div className="property">
        <GlobeSVG />
        <Typography className="property-title">{`Continent`}</Typography>
        <Typography className="property-value">{location.continent}</Typography>
      </div>

      <div className="property">
        <LocationSVG />
        <Typography className="property-title">{`Country`}</Typography>
        <Typography className="property-value">{location.country}</Typography>
      </div>

      <div className="property">
        <LocationSVG />
        <Typography className="property-title">{`City`}</Typography>
        <Typography className="property-value">{location.city}</Typography>
      </div>

      <div className="property">
        <LocationSVG />
        <Typography className="property-title">{`Street`}</Typography>
        <Typography className="property-value">{location.street}</Typography>
      </div>

      <div className="property">
        <HashSVG />
        <Typography className="property-title">{`Postal Code`}</Typography>
        <Typography className="property-value">{location.postalCode}</Typography>
      </div>

      <div className="property">
        <FlagSVG />
        <Typography className="property-title">{`Port`}</Typography>
        <Typography className="property-value">{location.port}</Typography>
      </div>

      {location.portUnloc&&<div className="property">
        <FlagSVG />
        <Typography className="property-title">{`Unloc`}</Typography>
        <Typography className="property-value">{location.portUnloc}</Typography>
      </div>}

      <div className="property">
        <MessageSVG />
        <Typography className="property-title">{`Comments`}</Typography>
        <Typography className="property-value">{location.comments}</Typography>
      </div>
    </div>
  </Box>
}

export default LocationCard;