import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import api from './lib/api';

import './index.css';
import { App } from './App';
import { Error } from './Error';
import { Success } from './Success';

api.init().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/error" element={<Error />} />
          <Route path="/:shipmentId" element={<App />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
});



