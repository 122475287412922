import { Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import {AiFillFile,AiFillFilePdf,AiFillFileText,AiFillFileImage,AiFillFileWord} from 'react-icons/ai';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  files,
  setFiles,
  ...otherProps
}) => {
  const fileInputField = useRef(null);

  const addToFiles = () => {
      setFiles(fileInputField.current.files)
  }

  const getIcon = (type) => {
    console.log(type);
    switch(type){
      case "text/plain":
        return <AiFillFileText size={16}/>
      case "image/jpeg":
        return <AiFillFileImage size={16}/>
      case "image/png":
        return <AiFillFileImage size={16}/>
      case "application/pdf":
        return <AiFillFilePdf size={16}/>
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <AiFillFileWord size={16}/>
      default:
        return <AiFillFile size={16}/>
    }
  }

  return (
    <>
    <Typography className="price-text">Files</Typography>
    <div style={{display:"flex", flexDirection:"column", paddingLeft:"1rem"}}>
      {Array.from(files).map((file,index) =>
        (<div key={index} style={{display:"flex", gap:"1rem", alignItems:"center"}}>
          <div>{getIcon(file.type)}</div>
          <div style={{fontSize:"16px"}}>{file.name}</div>
          {/* <div>{file.lastModifiedDate}</div> */}
        </div>)
      )}
    </div>
    <div>
      <input id="try" style={{display:"none"}} type="file" multiple accept="image/png, image/jpeg, application/pdf,application/vnd.ms-excel, text/plain, application/msword, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document" ref={fileInputField} onChange={addToFiles} />
      <label for="try">
        <a style={{borderColor:"black",border:"2px solid",padding:"4px"}}>Upload file</a>
      </label>
    </div>
    {/* <button>upload</button> */}
   </>
  )
}

export default FileUpload;