import React from 'react';
import { useParams } from 'react-router-dom';
import api from './lib/api';

import "./App.css";
import Form from './components/Form';

import { useNavigate } from "react-router-dom";
import { Success } from './Success';

export const App = (params) => {  
  let navigate = useNavigate();

  const { shipmentId } = useParams();
  const [ shipment, setShipment ] = React.useState(null);
  const [ clickedSent, setClickedSent ] = React.useState(false);

  React.useEffect(() => {
    api.queryShipmentById(shipmentId).then(response => {
      if (!response.success) {
        return navigate("/error/?message=" + response.message);
      }
      
      setShipment(response.data[0].body);
      document.title = `New bid - #${response.data[0].body.globalRecordNumber}`;
    });
  }, [ shipmentId, navigate ]);
  return <>
    { shipment && (clickedSent?<Success shipment={shipment} />:<Form shipment={shipment} setClickedSent={setClickedSent}/>) }
  </>
};