import React from 'react';

import { Typography } from "@mui/material";

import './Success.css';

export const Success = ({shipment}) => {
  return <div className="content">
  <div className="shipment-form">
    <div className="shipment-form__header">
      <Typography variant="h2" component="h1" gutterBottom>
      Thank you!
      </Typography>
      </div>
      <div className="shipment-form__body">
      <Typography variant="h6" component="h1" gutterBottom>
      Your bid has been placed for order #{shipment.globalRecordNumber}, from {`${shipment?.pickupFrom?.street??""} ${shipment?.pickupFrom?.city??""} ${shipment.pickupFrom?.county??""}`} to {`${shipment.deliverTo.street??""} ${shipment.deliverTo.city??""} ${shipment.deliverTo.county??""}`}
      </Typography>
      </div>
  </div>
  </div>
}