const DEFAULT_API_BASE_URL = ({
  development: `http://localhost:7070`,
  production: 'https://api.cargosys.net',
  staging: 'https://api.staging.cargosys.net',
  demo: 'https://api.demo.cargosys.net',
})[process.env.REACT_APP_NODE_ENV || 'development'];

const config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL
};

export default config;
