import config from "./config";
import { observable } from 'mobx';
import axios from "axios";
import global from "./global";


const internalState = observable({
  lockLevel: 0,
});

const api = observable({
  isReady: false,
  isBusy: false,
  lastError: null,
});

const setError = (error) => {
  if (!api.lastError) {
    api.lastError = error;
  }

  return error;
};

api.clearLastError = () => {
  api.lastError = null;
};

api.setLastError = ({ message }) => {
  setError({
    success: false,
    message: message,
  });
};

api.lock = () => {
  ++internalState.lockLevel;

  api.isBusy = internalState.lockLevel > 0;
};

api.unlock = () => {
  --internalState.lockLevel;

  api.isBusy = internalState.lockLevel > 0;
};

api.appBaseUrl = (() => {
  return `${window.location.protocol}//${window.location.host}`;
})();

api.now = () => {
  const bias = global.time.client.valueOf() - global.time.server.valueOf();
  const server = new Date().valueOf() - bias;

  return new Date(server);
};

api.reload = async ({ replace = true } = {}) => {
  api.navigateUrl(window.location.href, { replace: replace });
};

api.createAbsoluteUrl = (relativeUrl) => {
  if (
    relativeUrl.substr(0, 5) === "http:" ||
    relativeUrl.substr(0, 6) === "https:"
  ) {
    return relativeUrl;
  } else {
    return new URL(`unsecure/${relativeUrl}`, `${config.apiBaseUrl}`);
  }
};

api.fetch = async (relativeUrl,
    options = { method: "GET", rawResponse: false, responseType: "json" }, query) => {
  const url = api.createAbsoluteUrl(relativeUrl);

  const { own = false, silent = false, onValidationError = null } = query || {};

  if (own) {
    url.searchParams.set("own", "1");
  }

  if (!silent) api.lock();

  const result = await (async () => {
    try {
      const opt = {
        ...options,
        withCentials: false,
      };

      const response = await axios.request({
        url: url.href,
        ...opt,
      });

      if (options.rawResponse) {
        return response;
      }

      const setServerTimestamp = (timestamp) => {
        if (timestamp) {
          global.time.server = new Date(timestamp);
          global.time.client = new Date();
        }
      };

      const json = response.data;

      if (json) {
        setServerTimestamp(json.timestamp);
      }

      if (response.status >= 200 && response.status <= 299) {
        return json;
      } else {
        try {
          if (json.validation && onValidationError) {
            onValidationError(json.validation);
          } else if (!silent) {
            return setError(json);
          }
        } catch (err) {
          if (!silent) {
            return setError({ success: false, message: err.message });
          }
        }
      }

      return response;
    } catch (e) {
      if (e?.response?.data?.validation && onValidationError) {
        onValidationError(e.response.data.validation);

        return e.response.data;
      } else if (!silent) {
        if (e.response) {
          return setError(e.response.data);
        } else {
          return setError({ success: false, message: e.message });
        }
      } else {
        if (e.response) {
          return e.response.data;
        } else {
          return { success: false, message: e.message };
        }
      }
    }
  })();

  if (!silent) api.unlock();

  return result;
};

api.get = async (relativeUrl, query) => {
  return await api.fetch(relativeUrl, { method: "GET" }, query);
};

api.delete = async (relativeUrl, query) => {
  return await api.fetch(relativeUrl, { method: "DELETE" }, query);
};

api.put = async (relativeUrl, body, query) => {
  return await api.fetch(
    relativeUrl,
    {
      method: "PUT",
      data: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    },
    query
  );
};

api.post = async (relativeUrl, body, query) => {
  return await api.fetch(
    relativeUrl,
    {
      method: "POST",
      data: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    },
    query
  );
};

api.queryShipmentById = async (id) => {
  return await api.get(`shipments/${id}`);
};

api.outOfTime = async (orgId, orgName, shipmentId, shipmentName) => {
  return await api.post(`logs/outOfTime`, {
    orgId, orgName, shipmentId, shipmentName
  });
};

api.openForm = async (orgId, orgName, shipmentId, shipmentName) => {
  return await api.post(`logs/openForm`, {
    orgId, orgName, shipmentId, shipmentName
  });
};

api.createShipmentContract = async (contract) => {
  return await api.post("shipmentContracts", contract);
};

api.uploadFilesContact = (contactId,files) => {
  const bodyFormData = new FormData();
  for (let i = 0; i < files.length; i++) {
    console.log(files[i]);
    bodyFormData.append('files', files[i]);
  }
  return axios.post(`https://api.cargosys.net/unsecure/shipmentContracts/files/${contactId}`,bodyFormData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

api.updateShipmentContract = async (contract, { onValidationError = null }) => {
  const data = { ...contract };

  delete data._id;
  delete data.state;
  delete data.enabled;
  delete data.readOnly;

  return await api.put(`shipmentContracts/${contract._id}`, contract, {
    onValidationError: onValidationError,
  });
};

api.queryCurrencyExchangeRates = async (query) => {
  return await api.get("currency/exchangeRates", query);
};

api.queryOrgUnits = async (query) => {
  return await api.get("orgUnits", query);
};

api.init = async () => {
  if (api.isReady) return;

  const orgUnits = await api.queryOrgUnits();

  if (orgUnits.success) {
    global.orgUnits = orgUnits.data;
  }

  const rates = await api.queryCurrencyExchangeRates({ silent: true });

  if (!rates || !rates.success) {
    global.currencyExchangeRates = {};
  } else {
    global.currencyExchangeRates = rates.data;
  }

  api.isReady = true;
};

export default api;
