import React, { useMemo }  from 'react';

import { Divider, MenuItem, Select, TextField, Typography } from "@mui/material";
import global from "../lib/global";

const Price = ({ title, value, setFunc, currency, setCurrency, prefCurrencies, notPrefCurrencies , error}) => {
  const id = `${title.toLowerCase().replaceAll(" ", "-")}-price`;

  return <div className="price">
    <Typography className="price-text">{ title }</Typography>
    <TextField
      error={error}
      id={id}
      sx={{ width: '100%' }} 
      type="number"
      label={title}
      value={ value }
      variant="outlined"
      InputProps={{ inputProps: { min: 0 } }}
      onChange={ (e) => { setFunc(parseInt(e.target.value)) }}
    />

    <Select
      id="currency"
      sx={{ width: '100%' }}
      variant="outlined"
      value={ currency }
      onChange={(e, val) => { setCurrency(val.props.value) }}
    >
      {prefCurrencies.map((currency, index) => {
        return <MenuItem key={currency} value={currency}>
          <div style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', width: '100%' }}>
            <div>{currency}</div>
            <div>{global.currency[currency].symbol}</div>
          </div>
        </MenuItem>
      })};

      <Divider />

      {notPrefCurrencies.map((currency, index) => {
        return <MenuItem key={currency} value={currency}>
          <div style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', width: '100%' }}>
            <div>{currency}</div>
            <div className="text-right">{global.currency[currency].symbol}</div>
          </div>
        </MenuItem>
      })};
    </Select>
    
  </div>

}

export default Price;