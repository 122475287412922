import { TextField, Typography, Autocomplete, Select, MenuItem, Button } from "@mui/material";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FileUpload from "./FileUpload";

import React, { useEffect, useState } from "react";

import LocationCard from "./LocationCard";
import global from "../lib/global";
import "./Form.css"
import Price from "./Price";
import api from "../lib/api";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";

var currency_symbols = {
  'USD': '$', // US Dollar
  'EUR': '€', // Euro
  'CRC': '₡', // Costa Rican Colón
  'GBP': '£', // British Pound Sterling
  'ILS': '₪', // Israeli New Sheqel
  'INR': '₹', // Indian Rupee
  'JPY': '¥', // Japanese Yen
  'KRW': '₩', // South Korean Won
  'NGN': '₦', // Nigerian Naira
  'PHP': '₱', // Philippine Peso
  'PLN': 'zł', // Polish Zloty
  'PYG': '₲', // Paraguayan Guarani
  'THB': '฿', // Thai Baht
  'UAH': '₴', // Ukrainian Hryvnia
  'VND': '₫', // Vietnamese Dong
};

const fixShipment = (shipment) => {
  switch (shipment.shippingTerms) {
    case "D2D":
      shipment.shippingTerms = "Door to Door";
      break;
    case "D2P":
      shipment.shippingTerms = "Door to Port";
      break;
    case "P2D":
      shipment.shippingTerms = "Port to Door";
      break;
    case "P2P":
      shipment.shippingTerms = "Port to Port";
      break;
    default:
  }


  switch (shipment.transportType) {
    case "any":
      shipment.transportType = "Direct or Transshipment";
      break;
    case "direct":
      shipment.transportType = "Direct";
      break;
    case "transshipment":
      shipment.transportType = "Transshipment";
      break;
    default:
  }
}

const longShippingTerms = {
  "D2D": "Door to Door",
  "D2P": "Door to Port",
  "P2D": "Port to Door",
  "P2P": "Port to Port",
}

const getShipmentRequiredPriceFields = (shipment) => {
  const shippingTerms = shipment.shippingTerms;
  const genericMatrix = [
    { shippingTerms: [longShippingTerms['D2D'], longShippingTerms['D2P']], field: 'originHaulage' },
    { shippingTerms: [longShippingTerms['D2D'], longShippingTerms['D2P'], longShippingTerms['P2D'], longShippingTerms['P2P']], field: 'originCustomsClearance' },
    { shippingTerms: [longShippingTerms['D2D'], longShippingTerms['D2P'],], field: 'originTerminalHandlingCharges' },
    { shippingTerms: [longShippingTerms['D2D'], longShippingTerms['D2P'], longShippingTerms['P2D'], longShippingTerms['P2P']], field: 'originLocalCharges' },
    { shippingTerms: [longShippingTerms['D2D'], longShippingTerms['D2P'], longShippingTerms['P2D'], longShippingTerms['P2P']], field: 'freight' },
    { shippingTerms: [longShippingTerms['D2D'], longShippingTerms['D2P'], longShippingTerms['P2D'], longShippingTerms['P2P']], field: 'destLocalCharges' },
    { shippingTerms: [longShippingTerms['D2D'], longShippingTerms['D2P'], longShippingTerms['P2D'], longShippingTerms['P2P']], field: 'destCustomsClearance' },
    { shippingTerms: [longShippingTerms['D2D'], longShippingTerms['P2D'], longShippingTerms['P2P']], field: 'destTerminalHandlingCharges' },
    { shippingTerms: [longShippingTerms['D2D'], longShippingTerms['P2D']], field: 'destHaulage' },
  ];

  let fields = genericMatrix.filter(i => i.shippingTerms.includes(shippingTerms)).map(i => i.field);

  if (shipment.insurance) {
    fields.push('insurance');
  }

  if (shipment.incoterms === 'FOB') {
    fields = fields.filter(i => i !== 'originLocalCharges');
  }

  if (shipment.transportMode !== 'sea') {
    fields = fields.filter(i => !['originTerminalHandlingCharges', 'destTerminalHandlingCharges'].includes(i));
  }

  if (shipment.shipmentType === 'import') {
    // Import
    const originRequired = ['EXW'].includes(shipment.incoterms);
    const destRequired =
      shipment.customsClearance &&
      ['CFR', 'CIF', 'CIP', 'CPT', 'EXW', 'FAS', 'FCA', 'FOB'].includes(shipment.incoterms);

    if (!originRequired) {
      fields = fields.filter(i => !['originCustomsClearance'].includes(i));
    }

    if (!destRequired) {
      fields = fields.filter(i => !['destCustomsClearance'].includes(i));
    }
  } else {
    // Export
    const destRequired = ['DDP'].includes(shipment.incoterms);
    const originRequired =
      shipment.customsClearance &&
      ['CFR', 'CIF', 'CIP', 'CPT', 'DAP', 'DDP', 'DPU', 'FAS', 'FCA', 'FOB'].includes(shipment.incoterms);

    if (!originRequired) {
      fields = fields.filter(i => !['originCustomsClearance'].includes(i));
    }

    if (!destRequired) {
      fields = fields.filter(i => !['destCustomsClearance'].includes(i));
    }
  }

  return fields;
};

function capitalizeCamelCase(str) {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b([a-z])/g, c => c.toUpperCase());
}



const Form = ({ shipment, setClickedSent }) => {
  const orgUnits = global.orgUnits;
  const searchParams = new URLSearchParams(document.location.search)
  const [pricesData, setPricesData] = React.useState({});

  let navigate = useNavigate();

  // If expired, no need to render page
  useEffect(() => {
    if(!orgUnits.length) return;
    const found = orgUnits.find(i => i.body._id === searchParams.get('id'))
    if (!found)
      return navigate("/error/?message=" + "link is wrong!");
    setOrgUnit(found);

    if (new Date(shipment.expiresAt).getTime() < new Date().getTime()) {
      api.outOfTime(found.body._id, found.body.name, shipment._id, shipment.name)
      return navigate("/error/?message=Bidding time expired!");
    }

    api.openForm(found.body._id, found.body.name, shipment._id, shipment.name)

    
  }, [navigate, shipment.expiresAt,orgUnits]);


  const { prefCurrencies, notPrefCurrencies } = React.useMemo(() => {
    const prefCurrencies = [], notPrefCurrencies = [];
    for (let currency in global.currencyExchangeRates) {
      if (currency === "USD" || currency === "CAD" || currency === "EUR") prefCurrencies.push(currency);
      else notPrefCurrencies.push(currency);
    }
    return { prefCurrencies, notPrefCurrencies };
  }, []);


  useEffect(() => {
    if (shipment) {
      const fields = getShipmentRequiredPriceFields(shipment);
      setPricesData(fields.reduce((acc, curr) => {
        acc[curr] = {
          value: 0,
          currencyCode: prefCurrencies[2],
          isError: false
        };
        return acc;
      }, {}));
    }
  }, [shipment, prefCurrencies])




  fixShipment(shipment);

  // Global lists




  // Variables
  const [orgUnit, setOrgUnit] = React.useState(orgUnits[0]);
  const [carrier, setCarrier] = React.useState(shipment.transportMode === "air" ? global.airnames[0] : global.seanames[0]);
  const [estTransitDays, setEstTransitDays] = React.useState(0);
  const [quoteExpires, setQuoteExpires] = React.useState(new Date());
  const [comments, setComments] = React.useState("");
  const [files, setFiles] = React.useState([]);

  const [totalCost, setTotalCost] = React.useState(0);



  // Handlers
  // const handleOrgUnitChange = ({ orgUnit }) => {
  //   let org = orgUnits.find(org => org.body.name === orgUnit.props.value);
  //   if (org) setOrgUnit({ ...org });
  // };

  // useEffect(() => {
  
  // }, []);

  const submitBid = async () => {
    // if(!destinationHaulage && (shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Port to Door")){
    //   setDestinationHaulageError(true);
    //   return
    // } else setDestinationHaulageError(false);
    // if(!originHaulage && (shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Door to Port")){
    //   setOriginHaulageError(true);
    //   return
    // } else setOriginHaulageError(false)
    // if(!originCustomsClearance && (shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Door to Port" || shipment.shippingTerms === "Port to Door" || shipment.shippingTerms === "Port to Port") && shipment.incoterms !== 'FOB'){
    //   setOriginCustomsClearanceError(true);
    //   return
    // } else setOriginCustomsClearanceError(false)
    // if(!originLocalCharges && (shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Door to Port" || shipment.shippingTerms === "Port to Door" || shipment.shippingTerms === "Port to Port")){
    //   setOriginLocalChargesError(true);
    //   return
    // } else setOriginLocalChargesError(false);
    // if(!destinationLocalCharges && (shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Door to Port" || shipment.shippingTerms === "Port to Door" || shipment.shippingTerms === "Port to Port")){
    //   setDestinationLocalChargesError(true);
    //   return
    // } else setDestinationLocalChargesError(false)
    // if(!destinationTerminalHandlingCharges && (shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Port to Door" || shipment.shippingTerms === "Port to Port") && shipment.transportMode === 'sea'){
    //   setDestinationTerminalHandlingChargesError(true);
    //   return
    // } else setDestinationTerminalHandlingChargesError(false)
    // if(!originTerminalHandlingCharges && (shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Door to Port") && shipment.transportMode === 'sea'){
    //   setOriginTerminalHandlingChargesError(true);
    //   return
    // } else setOriginTerminalHandlingChargesError(false)
    // if(!freightAndSurcharges && (shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Door to Port" || shipment.shippingTerms === "Port to Door" || shipment.shippingTerms === "Port to Port")){
    //   setFreightAndSurchargesError(true);
    //   return
    // } else setFreightAndSurchargesError(false)
    const contract = {};
    contract.shipmentId = shipment._id;
    contract.orgUnitId = orgUnit.body._id;
    contract.freightCarrierName = carrier;
    contract.transitDays = estTransitDays;
    contract.expiresAt = quoteExpires;
    contract.comments = comments;
    contract.prices = pricesData;
    // contract.prices = {
    //   destHaulage: {
    //     value: destinationHaulage,
    //     currencyCode: destinationHaulageCurr
    //   },
    //   originHaulage: {
    //     value: originHaulage,
    //     currencyCode: originHaulageCurr
    //   },
    //   originCustomsClearance: {
    //     value: originCustomsClearance,
    //     currencyCode: originCustomsClearanceCurr
    //   },
    //   originLocalCharges: {
    //     value: originLocalCharges,
    //     currencyCode: originLocalChargesCurr
    //   },
    //   freight: {
    //     value: freightAndSurcharges,
    //     currencyCode: freightAndSurchargesCurr
    //   },
    //   destLocalCharges: {
    //     value: destinationLocalCharges,
    //     currencyCode: destinationLocalChargesCurr
    //   },
    //   destTerminalHandlingCharges: {
    //     value: destinationTerminalHandlingCharges,
    //     currencyCode: destinationTerminalHandlingChargesCurr
    //   },
    //   originTerminalHandlingCharges: {
    //     value: originTerminalHandlingCharges,
    //     currencyCode: originTerminalHandlingChargesCurr
    //   },
    //   insurance: {
    //     value: insurance,
    //     currencyCode: insuranceCurr
    //   }
    // }

    const result = await api.createShipmentContract(contract);



    if (result.success) {
      // result.data
      await api.uploadFilesContact(result.data.body._id, files)
        .catch(err => console.log(err));
      setClickedSent(true);
    } else {
      return navigate("/error/?message=" + result.message);
    }
  }

  const numericString = ({ value, feedback }) => {
    if (Number.isFinite(Number(value))) return value.toString();

    if (feedback) {
      return <span className="error">???</span>
    } else {
      return '???';
    }
  };


  const calcCBM = (width, height, depth, quantity) => {
    if (!width || !height || !depth) return '0';
    const volume = Math.max(0.01, ((width / 100) * (height / 100) * (depth / 100)).toFixed(2));
    return numericString({ value: volume * quantity, feedback: true })
  }


  // Hooks
  // useEffect(() => {
  //   const calculateTotalCost = () => {
  //     const total =
  //       ((originHaulage / global.currencyExchangeRates[originHaulageCurr]) | 0) +
  //       ((originCustomsClearance / global.currencyExchangeRates[originCustomsClearanceCurr]) | 0) +
  //       ((originLocalCharges / global.currencyExchangeRates[originLocalChargesCurr]) | 0) +
  //       ((freightAndSurcharges / global.currencyExchangeRates[freightAndSurchargesCurr]) | 0) +
  //       ((destinationLocalCharges / global.currencyExchangeRates[destinationLocalChargesCurr]) | 0) +
  //       ((destinationHaulage / global.currencyExchangeRates[destinationHaulageCurr]) | 0) +
  //       ((insurance / global.currencyExchangeRates[insuranceCurr]) | 0) +
  //       ((destinationTerminalHandlingCharges / global.currencyExchangeRates[destinationTerminalHandlingChargesCurr]) | 0) +
  //       ((originTerminalHandlingCharges / global.currencyExchangeRates[originTerminalHandlingChargesCurr]) | 0);
  //     setTotalCost(total);
  //   }

  //   calculateTotalCost();
  // }, [originHaulage, originHaulageCurr,
  //   originCustomsClearance, originCustomsClearanceCurr,
  //   originLocalCharges, originLocalChargesCurr,
  //   freightAndSurcharges, freightAndSurchargesCurr,
  //   destinationLocalCharges, destinationLocalChargesCurr,
  //   destinationHaulage, destinationHaulageCurr,
  //   insurance, insuranceCurr, originTerminalHandlingCharges, destinationTerminalHandlingCharges]);

  useEffect(() => {
    const total = Object.values(pricesData).reduce((acc, curr) => {
      acc += curr.value / global.currencyExchangeRates[curr.currencyCode];
      return acc;
    }, 0);
    setTotalCost(total);
  }, [pricesData])


  return <>{searchParams.get('id') && <div className="content">
    <div className="shipment-form">
      <Typography className="shipment-title">New bid - #{shipment.globalRecordNumber}</Typography>
      <Typography variant="h5">{shipment.name}</Typography>
      <div className="quick-summary">
        <Typography className="summary-text">{`${shipment.shippingTerms},
          ${shipment.incoterms}, ${shipment.shipmentType}${shipment.customsClearance && ", customs clearance"}, ${shipment.transportType},
          ${shipment.transportMode} transport of:`}
        </Typography>
        <svg className="summary-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21" /><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /><polyline points="3.27 6.96 12 12.01 20.73 6.96" /><line x1="12" y1="22.08" x2="12" y2="12" /></svg>
      </div>
      <div>
        {shipment.shipmentContent.map(content => {
          if (content.type === "container") {
            return <Typography>
              {`• ${content.quantity} ${content.type}, ${content.comments} (${content.containerType})`}
            </Typography>
          } else {
            const physicalProps = content.physicalProps;
            const cbm = calcCBM(physicalProps?.width, physicalProps?.height, physicalProps?.depth, content.quantity)
            return <Typography>
              {`• ${content.quantity} ${content.type}, ${content.comments} (`}
              <div className="tooltip" style={{ display: "inline" }}>
                {(Math.round(cbm * 100) / 100).toFixed(2)}
                <span class="tooltiptext">{cbm}</span>
              </div>
              {` CBM / ${(physicalProps.weight ?? 1) * content.quantity} Kg / Width: ${physicalProps.width} Cm / Height: ${physicalProps.height} Cm / Depth: ${physicalProps.depth} Cm)`}
            </Typography>
          }
        })}
      </div>
      <Typography variant="body1" style={{ paddingTop: "15px" }}>
        Pick Up Date: {Date(shipment.expiresAt).toString()}
      </Typography>

      <div className="locations">
        <LocationCard title="Origin" location={shipment.pickupFrom} />
        <LocationCard title="Destination" location={shipment.deliverTo} />
      </div>
      {/* 
      <div className="org-unit-selection">
        <Select
          id="currency"
          sx={{
            width: '100%'
          }}
          variant="outlined"
          value={ orgUnit.body.name }
          onChange={(e, val) => { handleOrgUnitChange({ orgUnit: val }) }}
        >
          {orgUnits.map((orgUnit, index) => {
            const shipmentOrgList = shipment.sharedWith.orgUnitIds;
            const found = shipmentOrgList.find(shipmentId => shipmentId === orgUnit.body._id)
            if(found != null){
            return <MenuItem key={orgUnit.body.name} value={orgUnit.body.name}>
              <div className="org-unit-wrapper" style={{ display: 'flex', width: '100%' }}>
                <div><img className="org-unit-picture" alt="org-unit" src={orgUnit.body.picture ? orgUnit.body.picture : ""}/></div>
                <div style={{marginLeft: '1rem'}}>{orgUnit.body.name}</div>
              </div>
            </MenuItem>
            }
          })};
        </Select>
      </div> */}

      <div className="basic-info">
        <Autocomplete
          disablePortal
          id="freight-carrier"
          sx={{ width: '90%' }}
          variant="outlined"
          value={carrier}
          options={shipment.transportMode === "air" ? global.airnames : global.seanames}
          renderInput={(params) => <TextField {...params}
            label="Freight Carrier"
            variant="outlined"
          />}
          onChange={(e, val) => { setCarrier(val) }}
        />

        <TextField
          id="est-transit-days"
          sx={{ width: '90%' }}
          type="number"
          value={estTransitDays}
          label="Est. Transit Days"
          variant="outlined"
          onChange={(e) => setEstTransitDays(parseInt(e.target.value))}
          InputProps={{ inputProps: { min: 0 } }}
        />
      </div>

      <div className="expiration-comments">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            id="quote-expires"
            label="Quote Expires"
            inputFormat="MM/dd/yyyy"
            variant="outlined"
            value={quoteExpires}
            onChange={setQuoteExpires}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <TextField
          id="comments"
          sx={{ width: '100%' }}
          label="Comments"
          variant="outlined"
          value={comments}
          onChange={(e) => setComments(e.target.value ? e.target.value : "")}
        />
      </div>

      <div className="prices">
        <div className="title">
          {/* <svg className="prices-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="1" x2="12" y2="23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg> */}
          <Typography className="prices-text">{`Prices`}</Typography>
        </div>

        <div className="price-inputs">
          {/* {(shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Door to Port") && <Price title="Origin Haulage"
            error={originHaulageError}
            value={originHaulage}
            setFunc={setOriginHaulage}
            currency={originHaulageCurr}
            setCurrency={setOriginHaulageCurr}
            prefCurrencies={prefCurrencies}
            notPrefCurrencies={notPrefCurrencies}
          />}
          <Price title="Origin Customs Clearance"
            error={originCustomsClearanceError}
            value={originCustomsClearance}
            setFunc={setOriginCustomsClearance}
            currency={originCustomsClearanceCurr}
            setCurrency={setOriginCustomsClearanceCurr}
            prefCurrencies={prefCurrencies}
            notPrefCurrencies={notPrefCurrencies}
          />
          {shipment.transportMode === 'sea' && (shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Door to Port") && <Price title="Origin Terminal Handling Charges"
            error={originTerminalHandlingChargesError}
            value={originTerminalHandlingCharges}
            setFunc={setOriginTerminalHandlingCharges}
            currency={originTerminalHandlingChargesCurr}
            setCurrency={setOriginTerminalHandlingChargesCurr}
            prefCurrencies={prefCurrencies}
            notPrefCurrencies={notPrefCurrencies}
          />}
          {shipment.incoterms !== 'FOB' && <Price title="Origin Local Charges"
            error={originLocalChargesError}
            value={originLocalCharges}
            setFunc={setOriginLocalCharges}
            currency={originLocalChargesCurr}
            setCurrency={setOriginLocalChargesCurr}
            prefCurrencies={prefCurrencies}
            notPrefCurrencies={notPrefCurrencies}
          />}
          <Price title="Freight & Surcharges"
            error={freightAndSurchargesError}
            value={freightAndSurcharges}
            setFunc={setFreightAndSurcharges}
            currency={freightAndSurchargesCurr}
            setCurrency={setFreightAndSurchargesCurr}
            prefCurrencies={prefCurrencies}
            notPrefCurrencies={notPrefCurrencies}
          />
          <Price title="Destination Local Charges"
            error={destinationLocalChargesError}
            value={destinationLocalCharges}
            setFunc={setDestinationLocalCharges}
            currency={destinationLocalChargesCurr}
            setCurrency={setDestinationLocalChargesCurr}
            prefCurrencies={prefCurrencies}
            notPrefCurrencies={notPrefCurrencies}
          />
          {shipment.transportMode === 'sea' && (shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Port to Door" || shipment.shippingTerms === "Port to Port") && <Price title="Destination Terminal Handling Charges"
            error={destinationTerminalHandlingChargesError}
            value={destinationTerminalHandlingCharges}
            setFunc={setDestinationTerminalHandlingCharges}
            currency={destinationTerminalHandlingChargesCurr}
            setCurrency={setDestinationTerminalHandlingChargesCurr}
            prefCurrencies={prefCurrencies}
            notPrefCurrencies={notPrefCurrencies}
          />}
          {(shipment.shippingTerms === "Door to Door" || shipment.shippingTerms === "Port to Door") && <Price title="Destination Haulage"
            error={destinationHaulageError}
            value={destinationHaulage}
            setFunc={setDestinationHaulage}
            currency={destinationHaulageCurr}
            setCurrency={setDestinationHaulageCurr}
            prefCurrencies={prefCurrencies}
            notPrefCurrencies={notPrefCurrencies}
          />}
          {shipment.insurance &&
            <Price title={`Insurance (Shipment value: ${shipment.shipmentValue.value} ${shipment.shipmentValue.currencyCode})`}
              value={insurance}
              setFunc={setInsurance}
              currency={insuranceCurr}
              setCurrency={setInsuranceCurr}
              prefCurrencies={prefCurrencies}
              notPrefCurrencies={notPrefCurrencies}
            />} */}
          {
            Object.keys(pricesData).map((key, index) => {
              const value = pricesData[key].value;
              const currency = pricesData[key].currencyCode;
              const error = pricesData[key].isError;
              const title = key === 'insurance' ? `${capitalizeCamelCase(key)} (Shipment value: ${shipment.shipmentValue.value} ${shipment.shipmentValue.currencyCode})` : capitalizeCamelCase(key);
              const setValue = (value) => {
                setPricesData({
                  ...pricesData,
                  [key]: {
                    ...pricesData[key],
                    value
                  }
                })
              }

              const setCurrency = (currency) => {
                setPricesData({
                  ...pricesData,
                  [key]: {
                    ...pricesData[key],
                    currencyCode: currency
                  }
                })
              }

              return <Price
                key={key}
                title={title}
                value={value}
                currency={currency}
                error={error}
                prefCurrencies={prefCurrencies}
                notPrefCurrencies={notPrefCurrencies}
                setFunc={setValue}
                setCurrency={setCurrency}
              />
            })
          }
          <div className="price">
            <FileUpload
              files={files}
              setFiles={setFiles}
            />
          </div>
        </div>

        <div className="total-price">
          <Typography className="total-price-title">{`Est. Total Cost: `}</Typography>
          <Typography className="total-price-value">{`$${(Math.round(totalCost * 100) / 100).toFixed(0)} USD`}</Typography>
        </div>
      </div>

      <div className="submit">
        <Button
          variant="contained"
          color="primary"
          onClick={() => { submitBid() }}
          sx={{ width: '100%', height: '100%', fontSize: '1.25rem' }}
        >
          Submit Bid
        </Button>
      </div>
    </div>
  </div>}</>
}

export default Form;