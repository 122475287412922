import React  from 'react';

import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useLocation } from 'react-router-dom';

import './Error.css';

function getQueryVariable(location, variable) {
  var query = location.search.substring(1);
  var vars = query.split('&');
  
  for(var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');

    if(decodeURIComponent(pair[0]) === variable)
        return decodeURIComponent(pair[1]);
  }

  return null;
}

export const Error = () => {
  const location = useLocation();

  return <Grid container spacing={0} direction="column" alignItems="center"
                justifyContent="center" style={{ height: '100vh' }}>
    <Grid item xs={3}>
      <Box className="error-box">
        <Typography variant="h3">{getQueryVariable(location, "message")}</Typography>
      </Box>
    </Grid>
  </Grid>
}